import { getAppURLFromHash, getQueryParams } from '../utils'

const QS_KEYWORD = 'v'
const S_VERSION = { name: 'version' }
const S_URL = { name: 'url' }
const S_SLOW_CONTROL = { name: 'slowControl' }
const S_REVERB_MODE = { name: 'reverbMode' }
const S_VIDEO_FX = { name: 'videoFx' }
const S_LOW_PASS_MODE = { name: 'lowPassMode' }
const S_LOFI_TAPE_MODE = { name: 'lofiTapeMode' }
const S_TRACK_NAME = { name: 'trackName' }
const S = [S_VERSION, S_URL, S_SLOW_CONTROL, S_REVERB_MODE, S_VIDEO_FX, S_LOW_PASS_MODE, S_LOFI_TAPE_MODE, S_TRACK_NAME]

export function decompressState (compressedState) {
  const state = {}
  compressedState.forEach((value, index) => state[S[index].name] = value)
  return state
}

export function decodeState (stateStr) {
  return decompressState(JSON.parse(decodeURIComponent(escape(atob(stateStr)))))
}

export function decodeStateFromQueryString (queryString) {
  if (queryString[QS_KEYWORD]) {
    try {
      return decodeState(queryString[QS_KEYWORD])
    } catch (error) {
      console.error(error)
      return null
    }
  }
}

export function decodeStateFromUrl (url) {
  const { host, hash, search: originSearch } = new URL(url)
  const { search } = host === 'www.slowed.app' ? { search: originSearch } : getAppURLFromHash(hash)
  if (!search) return {}
  return decodeStateFromQueryString(getQueryParams(search))
}
