async function render () {
  const html = `
    <div class="banner"></div>
    
    <!-- <div class="announcement">
      <code>UPDATE: 18 Feb 2024</code></br></br>
      
      <p>
        We're aware of reports indicating issues with the app's functionality.
      </p>

      <p>
        The issue has been fixed in version <b>v0.9.1</b> and is already available to update.
      </p>

      <p>
        Make sure to update the extension to the latest version <a href="chrome://extensions">here</a>.
      </p>

      <p>Slowed Team.</p>
    </div> -->

    <div class="container">
      <h1>Help</h1>

      <p>
        Are you experiencing issues or would you like to propose new ideas?
      </p>
      <p>
        Drop us an email at <a href="mailto:support@slowed.app">support@slowed.app</a> or <a href="/feedback">send a feedback</a> here.
      </p>

      <br/><br/>

      <h2>FAQ</h2>

      <h3>I bought my PRO and switched notebooks. How to activate again?</h3>

      <p>
        To activate your license in the new Notebook, follow these steps:
        <ol>
          <li>Make sure you're logged in Chrome using your purchased email. If you don't know you can check purchase receipt.</li>
          <li>Open the extension by clicking on the icon in the top right.</li>
          <li>Click on the <b>"UNLOCK PRO"</b> button at the top left. It will open the pop-up.</li>
          <li>Click on the <b>"UNLOCK"</b> button at the bottom of the pop-up. It will send you an email with the activation link to your inbox.</li>
          <li>Open your email inbox and click on the email with the title <b>"Unlock Slowed - Pro"</b>. There will be an activation link and you should be all set.</li>
        </ol>

        Sometimes the email can go to spam inbox. If you're still having problems send an email to <a href="mailto:support@slowed.app">support@slowed.app</a>.
      </p>
    </div>
  `

  document.getElementById('content').innerHTML = html
}

export async function init () {
  await render()
}
