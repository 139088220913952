async function render () {
  const html = `
    <div class="banner"></div>
    
    <div class="container">
      <h1>Terms of Service</h1>
      
      <p>
        These Terms of Service govern your use of Slowed App and any related services provided by Purabucha. 
        By accessing Slowed App, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations.
      </p>

      <p>
        This agreement extends to your use of any product generated for you by Slowed App, including but 
        not limited to our “Terms of Service” and “Privacy Policy” documents.
      </p>

      <p>
        If you do not agree with these Terms of Service, you are prohibited from using or accessing Slowed App 
        and from using any other services we provide. We reserve the right to review and amend any of these Terms of Service 
        at our sole discretion. Upon doing so, we will update this page. 
        Any changes to these Terms of Service will take effect immediately from the date of publication.
      </p>

      <h3>
        Limitations of Use
      </h3>

      <p>
        By using Slowed App and our website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:
        
        <ul>
          <li>Modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on the Slowed App;</li>
          <li>Remove any copyright or other proprietary notations from any materials and software on the Slowed App;</li>
          <li>Transfer the materials from the Slowed App to another person or “mirror” the materials on any other server;</li>
          <li>Knowingly or negligently use the Slowed App or any of its associated services in a way that abuses or disrupts our networks or any other service we provide;</li>
          <li>Use Slowed App or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
          <li>Use Slowed App or its associated services in violation of any applicable laws or regulations;</li<
          <li>Use Slowed App in conjunction with sending unauthorized advertising or spam;</li>
          <li>Harvest, collect, or gather user data without the user’s consent; or use Slowed App or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
        </ul>
      </p>
    
      <h3>
        Intellectual Property
      </h3>

      <p>
        The intellectual property in the materials in Slowed App are owned by or licensed to Purabucha. 
        You may download Slowed App, to view, use, and display the application on your browser device for your personal use only.
      </p>

      <p>
        This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate 
        if you violate any of these restrictions or these Terms of Service, and may be terminated by Purabucha at any time.
      </p>

      <h3>
        Automatic Updates
      </h3>

      <p>
        You give us permission to download and install updates to Slowed App on your device in accordance 
        with your privacy preferences. This permission can be revoked at any time by deleting Slowed App from your device.
      </p>

      <h3>
        Liability
      </h3>
      
      <p>
        Slowed App and the materials in Slowed App are provided on an 'as is' basis. 
        To the extent permitted by law, Purabucha makes no warranties, expressed or implied, and 
        hereby disclaims and negates all other warranties including, without limitation, 
        implied warranties or conditions of merchantability, fitness for a particular purpose, or 
        non-infringement of intellectual property, or other violation of rights.
      </p>

      <p>
        In no event shall we or our suppliers be liable for any consequential loss suffered or incurred by you or any 
        third party arising from the use or inability to use Slowed App, even if we or an authorized representative 
        has been notified, orally or in writing, of the possibility of such damage. 
      </p>
      
      <p>
        In the context of this agreement, we define “consequential loss” as including any consequential loss, indirect loss, 
        real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of 
        opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, 
        contract, equity, tort (including negligence), indemnity or otherwise.
      </p>

      <p>
        Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for 
        consequential or incidental damages, these limitations may not apply to you.
      </p>

      <h3>
        Accuracy of Materials
      </h3>

      <p>
        Products generated by Slowed App and the materials appearing on the Slowed App website are for 
        general information purposes only. It takes more than a nicely worded Terms of Service and Privacy Policy 
        for a business to be compliant with applicable laws and regulations. We do not warrant or make any 
        representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, 
        or otherwise relating to such materials or on any resources linked to this website.
      </p>

      <p>
        Furthermore, products generated by Slowed App do not guarantee publication on third-party platforms, 
        such as the Apple App Store or Google Play Store.
      </p>

      <h3>
        Links
      </h3>

      <p>
        We have not reviewed all of the sites linked to its website and are not responsible for the contents 
        of any such linked site. The inclusion of any link does not imply our endorsement, approval or control of the site. 
        Navigating to any such linked site is at your own risk, and you should make your own investigations with 
        respect to the suitability of those sites.
      </p>

      <h3>
        Right to Terminate
      </h3>

      <p>
        We may suspend or terminate your right to use our website and terminate these Terms of Service 
        immediately upon written notice to you for any breach of these Terms of Service.
      </p>

      <h3>
        Severance
      </h3>

      <p>
        Any term of these Terms of Service which is wholly or partially void or unenforceable is 
        severed to the extent that it is void or unenforceable. The validity of the remainder of these 
        Terms of Service is not affected.
      </p>

      <h3>
        Governing Law
      </h3>

      <p>
        These Terms of Service are governed by and construed in accordance with the laws of United States of America. 
        You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
      </p>
    </div>
  `

  document.getElementById('content').innerHTML = html
}

export async function init () {
  await render()
}
