import './index.css'

import logoPro from './logo-pro.svg'
import proBadge from './pro-badge.svg'

// Temporary
import './payhook.css'

async function render ({ upgraded, extensionNotInstalled }) {
  const upgradeHtml = `
    <div class="upgrade-container">
      <div class="container">
        <div class="upgrade-logo logo logo--pro">
          <img src="${logoPro}" />
        </div>

        <div class="page-upgrade">
          <div class="checkout">
            <div class="feature"><div>FREE</div> <div>Slow control</div></div>
            <div class="feature"><div>FREE</div> <div>1 Reverb mode</div></div>
            <div class="feature"><div>FREE</div> <div>3 Video effects</div></div>

            <div class="checkout-separator"></div>

            <div class="feature feature--pro"><div><img src="${proBadge}" /></div><div>Unlimited reverb modes</div></div>
            <div class="feature feature--pro"><div><img src="${proBadge}" /></div><div>Unlimited video effects</div></div>
            <div class="feature feature--pro"><div><img src="${proBadge}" /></div><div>Low pass mode</div></div>
            <div class="feature feature--pro"><div><img src="${proBadge}" /></div><div>Lofi tape mode</div></div>
            <div class="feature feature--pro"><div><img src="${proBadge}" /></div><div>Dark mode</div></div>
            <div class="feature feature--pro"><div><img src="${proBadge}" /></div><div>Macros</div></div>

            <div class="checkout-separator"></div>
            <div class="checkout-separator"></div>
            <div class="checkout-separator"></div>
            
            <div class="summary-item"><div>License</div> <div class="license">Lifetime</div></div>
            <div class="summary-item summary-item--total"><div>Total</div> <div><span>$ 7.00</span> $ 5.00</div></div>
          </div>

          <div id="payhook-button" class="checkout-email"></div>
        </div>
      </div>
    </div>
  `

  const upgradedHtml = `
    <div class="upgrade-container">
      <div class="container">
        <div class="upgrade-logo logo logo--pro">
          <img src="${logoPro}" />
        </div>

        <p style="text-align: center">
          <b>Congratulations.</b> You're now PRO! Enjoy your features.
        </p>

        <p>
          ${extensionNotInstalled
            ? 'Extension is not installed, <a href="/install">click here to install</a>'
            : 'Open the extension now!'
          }
        </p>
      </div>
  `

  const html = `
    ${upgraded ? upgradedHtml : upgradeHtml}
  `

  document.getElementById('content').innerHTML = html
}

export async function init ({ upgraded }) {
  await render({ upgraded })

  const urlSearchParams = new URLSearchParams(window.location.search)
  let { email, extensionId, paymentHookId, testMode } = Object.fromEntries(urlSearchParams.entries())
  const { origin } = new URL(window.location.href)

  extensionId = extensionId || process.env.CHROME_EXTENSION_ID
  testMode = testMode || process.env.NODE_ENV === 'development'

  const {
    productId,
    priceId,
    priceIdByCountry
  } = (testMode ? {
    productId: process.env.STRIPE_PRO_PRODUCT_ID_TEST,
    priceId: process.env.STRIPE_PRO_PRICE_ID_TEST,
    priceIdByCountry: {}
  } : {
    productId: process.env.STRIPE_PRO_PRODUCT_ID,
    priceId: process.env.STRIPE_PRO_PRICE_ID,
    priceIdByCountry: { BR: process.env.STRIPE_PRO_BRL_PRICE_ID }
  })

  if (upgraded) {
    console.log('Upgrading to PRO')

    try {
      chrome.runtime.sendMessage(extensionId, { command: 'upgraded', data: { upgradedAt: new Date() } })
    } catch (error) {
      console.error(error)
      return await render({ upgraded, extensionNotInstalled: true })
    }

    return
  }

  const unlockButton = new Payhook.UnlockButton({
    paymentHookId,
    productId,
    priceId,
    priceIdByCountry,
    accountId: process.env.STRIPE_ACCOUNT_ID,
    cancelUrl: `${origin}/upgrade${window.location.search}`,
    successUrl: `${origin}/upgraded${window.location.search}`,
    testMode
  })

  await unlockButton.init('payhook-button', { email })
}
