async function render () {
  const html = `
    <div class="container">
      <h1>Help</h1>

      <p>
        Are you experiencing issues or would you like to propose new ideas?
      </p>
      <p>
        Drop us an email at <a href="mailto:support@slowed.app">support@slowed.app</a> or <a href="/feedback">send a feedback</a> here.
      </p>
      <p>
        We usually respond in less than 1 day.
      </p>

      <br/><br/>

      <h2>FAQ</h2>

      <p>
        Work in progress
      </p>
    </div>
  `

  document.getElementById('content').innerHTML = html
}

export async function init () {
  await render()
}
