import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import logo from './logo.svg'

async function render () {
  const animate = false // sessionStorage.getItem('animation.status') !== 'viewed'

  const html = `
    <div class="container ${!animate && 'animation animation--skip'}">
      <div class="container__focus">
        <div class="logo animation animation animation-1">
          <img src="${logo}" />
        </div>
      
        <div id="demo" class="browser animation animation-2">
          <div class="browser__header">
            <div class="browser__action-bar animation animation-2-1">
              <div class="browser__action-bar__button animation animation-2-1-1"></div>
              <div class="browser__action-bar__button animation animation-2-1-2"></div>
              <div class="browser__action-bar__button animation animation-2-1-3"></div>
            </div>

            <div class="browser__navigation-bar animation animation-3">
              <div class="browser__navigation-bar__buttons animation animation-3-1">
                <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.95823 4.54486H9.68549V5.84057H2.95823L5.79743 8.822L4.933 9.72955L0.612732 5.19272L4.933 0.655881L5.79743 1.56343L2.95823 4.54486Z" fill="currentColor" />
                </svg>
                <svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.58462 4.54486L4.74542 1.56343L5.60985 0.655881L9.93012 5.19272L5.60985 9.72955L4.74542 8.822L7.58462 5.84057H0.856445V4.54486H7.58462Z" fill="currentColor" />
                </svg>
              </div>
              <div class="browser__navigation-bar__address animation animation-3-2">
                <span class="animation animation-3-2-1">
                  https://www.<span class="browser__navigation-bar__address__domain">youtube</span>.com/watch?v=OeCS-DZsgKM
                </span>
              </div>
              <div class="browser__navigation-bar__extension animation animation-3-3">
                <div class="browser__extension animation animation-3-3-1"></div>
              </div>
            </div>
          </div>

          <div class="browser__body">
            <div class="browser__content-states">
              <div class="browser__content-state browser__content-state--1 animation animation--hiddeable animation-4">
                <div class="video video--active animation animation-4-1">
                  <div class="video__title animation animation-4-1-1">Radiohead // No Surprises</div>
                  <div class="video__media-wrapper">
                    <video class="video__media" preload="auto" autoplay playsinline loop muted>
                      <source src="https://i.imgur.com/v7oZZ80.mp4" type="video/mp4">
                    </video>
                    <div class="video__controls">
                      <div class="video__progress animation animation-4-1-2">
                        <div class="video__progress__area"></div>
                      </div>
                    </div>
                    <div class="video__shadow animation animation-4-1-3"></div>
                  </div>
                </div>
              </div>

              <div class="browser__content-state browser__content-state--2 animation animation-5">
                <div class="video video--freeze video--fade">
                  <div class="video__title">Radiohead // No Surprises</div>
                  <div class="video__media-wrapper">
                    <video class="video__media" preload="auto" playsinline loop muted>
                      <source src="https://i.imgur.com/v7oZZ80.mp4" type="video/mp4">
                    </video>
                    <div class="video__controls">
                      <div class="video__progress">
                        <div class="video__progress__area"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="browser__content-state browser__content-state--3 animation animation-6">
                <div class="split">
                  <div class="split__item">
                    <div class="video video--active">
                      <div class="video__media-wrapper">
                        <div class="thumbnail__fx" style="z-index: 2">
                        </div>
                        <video class="video__media" preload="auto" autoplay playsinline loop muted>
                          <source src="https://www.slowed.app/radiohead.mp4" type="video/mp4">
                        </video>
                        <div class="video__controls">
                          <div class="video__progress">
                            <div class="video__progress__area"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="split__item">
                    <div class="popup-wrapper animation animation-6-1">
                      <div class="popup-wrapper__inner">
                        <div id="popup"></div>
                      </div>
                      <div class="popup-wrapper__shadow animation animation-6-2"></div>
                    </div>
                    <svg viewBox="11.8 9 16 22" class="mouse animation animation--hiddeable animation-6-3"><path d="M20,21l4.5,8l-3.4,2l-4.6-8.1L12,29V9l16,12H20z"></path></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
        <div class="product animation animation-7">
          <div class="product__title">Meet Slowed</div>
          <div class="product__text">
            <p>
              Rediscover songs by slowing down. <br/>
              Now available at the Chrome Web Store.
            </p>
          </div>

          <a class="install-cta" href="/install">
            <div class="install-cta__button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="4"></circle><line x1="21.17" y1="8" x2="12" y2="8"></line><line x1="3.95" y1="6.06" x2="8.54" y2="14"></line><line x1="10.88" y1="21.94" x2="15.46" y2="14"></line></svg><span>Install extension</span></div>  
          </a>

          <div class="menu">
            <div class="menu__links">
              <a href="/privacy">Privacy</a>
              <a href="/terms">Terms</a>
              <a href="/help">Help</a>
              <a href="/about">About</a>
              <a href="/feedback">Feedback</a>
            </div>
          </div>
        </div>

        <div class="footer animation animation-7">
          <a class="team-text" href="https://www.purabucha.com" target="_blank">
            2025 Slowed by Purabucha
          </a>
        </div>
      </div>
    </div>
  `

  document.getElementById('content').innerHTML = html
}

export async function init () {
  await render()

  setTimeout(() => {
    sessionStorage.setItem('animation.status', 'viewed')
  }, 500)

  ReactDOM.render(
    React.createElement(window.Slowed.Components.PopupDemo, {
      isPro: true,
      isFeedbackEnabled: true,
      isRateAppEnabled: true,
      isBuyCoffeeEnabled: true,
      onUpgradeClick: () => { window.location.pathname = '/upgrade' }
    }, null),
    document.getElementById('popup')
  )
}
